.k-chat {
  width: 100% !important;
  max-width: 1200px !important;
  height: 86vh !important;
  border-radius: 15px !important;
}

.k-input {
  border-radius: 15px !important;
}
.k-input-inner {
  min-height: 60px !important;
}

.k-alt {
  .k-chat-bubble {
    background-color: #363636e6 !important;
    border-color: #1976d2 !important;
    color: white !important;
  }
}

/* style.css */
.chat-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.chat-image {
  position: absolute;
  top: 40;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2; /* Ensures the image is above other elements */
}
.k-timestamp {
  text-align: center; /* Ensure the timestamp text is centered */
}
@media (max-width: 600px) {
  .k-input-inner {
    min-height: 40px !important;
  }
}
