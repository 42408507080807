.sigin-in-background-overlay {
  /* You may add things like width, height, background-size... */
  background-image: url("../images/login-page-bg.jpg");
  background-color: #ffffffe0;
  background-blend-mode: overlay;
  width: 100vw;
  height: 100vh;
  background-size: cover; /* Ensure the image covers the entire container */
  background-repeat: no-repeat; /* Prevent the image from repeating */
  background-position: center; /* Center the image */
  position: absolute;
}
